<template>
  <div class="flex items-center px-1 ml-1 bg-gray-600 rounded grow-0">
    <label class="dark:text-blue-400">{{ label }}</label>
    <input
      v-model="model"
      type="text"
      :min="inputMin"
      :max="inputMax"
      :step="componentInputStep"
      class="block w-12 p-1 leading-tight text-center text-white bg-gray-600 border-2 border-gray-600 rounded-sm text-2xs focus:ring-opacity-50 focus:outline-none focus:bg-gray-600 focus:text-white"
      style="cursor: col-resize"
      @mousedown="onMouseDown"
      @focus="attachFocus"
      @blur="detachFocus"
    />
  </div>
</template>
<script>
import inputs from './InputMixin'
import PositionComponentMode from '@src/enums/positionComponentMode'

export default {
  name: 'PositionInputSingle',

  mixins: [inputs],

  props: {
    componentMode: {
      type: PositionComponentMode,
      default: () => PositionComponentMode.UNSET
    },
    label: {
      type: String,
      default: ''
    },
    val: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      model: this.val,
      distance: 0,
      prevPointer: [0, 0],
      pointer: [0, 0]
    }
  },

  watch: {
    val(newValue, oldValue) {
      let value = Number(newValue)
      if (isNaN(value)) {
        value = oldValue
      }

      if (this.model !== value) {
        this.model = value
      }
    }
  },

  beforeDestroy() {
    this.cleanupListeners() // Just in case this component is destroyed while still in focus
  },

  computed: {
    componentInputStep() {
      // Used to set a step value for the input, for specific
      // component modes. If we don't have a mode, we fallback
      // to the default step value.
      if (this.row.step) return this.row.step

      // Fallback
      if (this.componentMode === PositionComponentMode.UNSET)
        return this.inputStep

      // TODO: If we expand to more than two modes, change this to a switch
      return this.componentMode === PositionComponentMode.POSITION ? 0.1 : 0.01
    }
  },

  methods: {
    cleanupListeners() {
      this.detachFocus()
      this.onMouseUp()
    },

    attachFocus(evt) {
      // document.addEventListener('mousewheel', this.onMouseWheel, false)
      // document.addEventListener('MozMousePixelScroll', this.onMouseWheel, false) // firefox
    },

    detachFocus() {
      // document.removeEventListener('mousewheel', this.onMouseWheel, false)
      // document.removeEventListener('MozMousePixelScroll', this.onMouseWheel, false) // firefox
    },

    // onMouseWheel(event) {
    //   event.preventDefault()
    //   event.stopPropagation()

    //   var delta = 0

    //   if (event.wheelDelta !== undefined) {
    //     // WebKit / Opera / Explorer 9
    //     delta = event.wheelDelta
    //   } else if (event.detail !== undefined) {
    //     // Firefox
    //     delta = -event.detail
    //   }

    //   // if (delta > 0) {
    //   //   constraint.dollyOut(getZoomScale())
    //   // } else if (delta < 0) {
    //   //   constraint.dollyIn(getZoomScale())
    //   // }

    //   // scope.update()
    //   // scope.dispatchEvent(startEvent)
    //   // scope.dispatchEvent(endEvent)
    //   // console.log('on mouse wheel', event)

    //   var currentValue = this.model

    //   console.log(delta)

    //   // var value = currentValue + (delta / (event.shiftKey ? 1 : 5)) // * this.inputStep
    //   // value = Math.min(this.inputMax, Math.max(this.inputMin, value))

    //   // if (currentValue !== value) {
    //   //   this.setValue(value)
    //   // }

    // },

    onMouseDown(event) {
      this.distance = 0
      this.onMouseDownValue = this.model
      this.prevPointer = [event.clientX, event.clientY]

      document.addEventListener('mousemove', this.onMouseMove, false)
      document.addEventListener('mouseup', this.onMouseUp, false)
    },

    onMouseMove(event) {
      var currentValue = this.model

      this.pointer = [event.clientX, event.clientY]
      this.distance +=
        this.pointer[0] -
        this.prevPointer[0] -
        (this.pointer[1] - this.prevPointer[1])

      var value =
        this.onMouseDownValue +
        (this.distance / (event.shiftKey ? 2.5 : 25)) * this.componentInputStep
      value = Math.min(this.inputMax, Math.max(this.inputMin, value))

      if (currentValue !== value) {
        this.setValue(value)
      }

      this.prevPointer = [event.clientX, event.clientY]
    },

    onMouseUp(event) {
      document.removeEventListener('mousemove', this.onMouseMove, false)
      document.removeEventListener('mouseup', this.onMouseUp, false)
    },

    setValue(value) {
      if (!isNaN(value)) this.model = value
    }
  }
}
</script>
